import { WalletId } from 'crypto/interface'
import React from 'react'
import EternlLogo from 'ui/Icons/components/EternlLogo'
import MeldLogo from 'ui/Icons/components/MeldLogo'
import MetamaskLogo from 'ui/Icons/components/MetamaskLogo'
import NamiLogo from 'ui/Icons/components/NamiLogo'

function WalletLogo({
  walletId,
  ...props
}: { walletId: WalletId } & React.SVGProps<SVGSVGElement>) {
  switch (walletId) {
    case 'metamask':
      return <MetamaskLogo {...props} />
    case 'nami':
      return <NamiLogo {...props} />
    case 'eternl':
      return <EternlLogo {...props} />
    default:
      return <MeldLogo {...props} />
  }
}

export default WalletLogo
