import * as React from 'react'

function MetamaskLogo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        transform="translate(3 4)"
        fillRule="nonzero"
        stroke="none"
        strokeWidth={1}
        fill="none"
      >
        <path
          fill="#CDBDB2"
          d="M4.94415115 14.185673L7.20835502 14.7864103 7.20835502 14.0008434 7.39318464 13.8160138 8.68699195 13.8160138 8.68699195 14.7401619 8.68699195 15.3870655 7.30076983 15.3870655 5.59105481 14.6477471z"
        />
        <path
          fill="#CDBDB2"
          transform="matrix(-1 0 0 1 20.978 0)"
          d="M8.64074347 14.185673L10.8586989 14.7864103 10.8586989 14.0008434 11.0435285 13.8160138 12.3373358 13.8160138 12.3373358 14.7401619 12.3373358 15.3870655 10.9511137 15.3870655 9.24148079 14.6477471z"
        />
        <path
          fill="#393939"
          d="M7.39318464 12.4760401L7.20835502 14.0008434 7.43935097 13.8160138 9.84213597 13.8160138 10.1193804 14.0008434 9.93455078 12.4760401 9.56489155 12.2449621 7.71659539 12.2912105z"
        />
        <path
          fill="#F89C35"
          d="M6.14554366 2.17174799L7.25452135 4.75936261 7.76284387 12.2912105 9.56489155 12.2912105 10.1193804 4.75936261 11.1359433 2.17174799z"
        />
        <path
          fill="#F89D35"
          d="M1.29380731 7.85529975L0 11.5980584 3.23451828 11.4132288 5.31381038 11.4132288 5.31381038 9.79601072 5.22139558 6.46907763 4.75932154 6.83873686z"
        />
        <path
          fill="#D87C30"
          d="M3.74275865 8.27112531L7.53176577 8.36354012 7.11594021 10.3042511 5.31381038 9.84217705z"
        />
        <path
          fill="#EA8D3A"
          d="M3.74275865 8.31737379L5.31381038 9.79601072 5.31381038 11.2746476z"
        />
        <path
          fill="#F89D35"
          d="M5.31381038 9.84217705L7.16210654 10.3042511 7.76284387 12.2912105 7.34693616 12.5222065 5.31381038 11.320814z"
        />
        <path
          fill="#EB8F35"
          d="M5.31381038 11.320814L4.94415115 14.185673 7.39318464 12.4760401z"
        />
        <path
          fill="#EA8E3A"
          d="M7.53176577 8.36354012L7.76284387 12.2912105 7.06969173 10.2811679z"
        />
        <path
          fill="#D87C30"
          d="M3.1882698 11.3670625L5.31381038 11.320814 4.94415115 14.185673z"
        />
        <path
          fill="#EB8F35"
          d="M1.01656289 15.433314L4.94415115 14.185673 3.1882698 11.3670625 -2.9184298e-16 11.5980584z"
        />
        <path
          fill="#E8821E"
          d="M7.25452135 4.75936261L5.26764405 6.42282915 3.74275865 8.27112531 7.53176577 8.4097886z"
        />
        <path
          fill="#DFCEC3"
          d="M4.94415115 14.185673L7.39318464 12.4760401 7.20835502 13.9546771 7.20835502 14.7864103 5.54488848 14.4629174z"
        />
        <path
          fill="#DFCEC3"
          transform="matrix(-1 0 0 1 22.272 0)"
          d="M9.93455078 14.185673L12.3373358 12.4760401 12.1525062 13.9546771 12.1525062 14.7864103 10.4890396 14.4629174z"
        />
        <path
          fill="#393939"
          transform="matrix(-1 0 0 1 12.245 0)"
          d="M5.72971809 9.19527339L5.22139558 10.2580848 7.0235254 9.79601072z"
        />
        <path
          fill="#E88F35"
          d="M0.97031441 0.0000410725908L7.25452135 4.75936261 6.19179213 2.17174799z"
        />
        <path
          d="M.97 0L.14 2.541.6 5.314l-.324.185.462.416-.37.323.509.462-.324.277.74.924L4.759 6.84c1.695-1.356 2.526-2.049 2.496-2.08C7.224 4.73 5.129 3.142.97 0z"
          fill="#8E5A30"
        />
        <g transform="matrix(-1 0 0 1 17.281 0)">
          <path
            fill="#F89D35"
            d="M1.29380731 7.85529975L-2.00642049e-16 11.5980584 3.23451828 11.4132288 5.31381038 11.4132288 5.31381038 9.79601072 5.22139558 6.46907763 4.75932154 6.83873686z"
          />
          <path
            fill="#D87C30"
            d="M3.74275865 8.27112531L7.53176577 8.36354012 7.11594021 10.3042511 5.31381038 9.84217705z"
          />
          <path
            fill="#EA8D3A"
            d="M3.74275865 8.31737379L5.31381038 9.79601072 5.31381038 11.2746476z"
          />
          <path
            fill="#F89D35"
            d="M5.31381038 9.84217705L7.16210654 10.3042511 7.76284387 12.2912105 7.34693616 12.5222065 5.31381038 11.320814z"
          />
          <path
            fill="#EB8F35"
            d="M5.31381038 11.320814L4.94415115 14.185673 7.34693616 12.5222065z"
          />
          <path
            fill="#EA8E3A"
            d="M7.53176577 8.36354012L7.76284387 12.2912105 7.06969173 10.2811679z"
          />
          <path
            fill="#D87C30"
            d="M3.1882698 11.3670625L5.31381038 11.320814 4.94415115 14.185673z"
          />
          <path
            fill="#EB8F35"
            d="M1.01656289 15.433314L4.94415115 14.185673 3.1882698 11.3670625 -2.00642049e-16 11.5980584z"
          />
          <path
            fill="#E8821E"
            d="M7.25452135 4.75936261L5.26764405 6.42282915 3.74275865 8.27112531 7.53176577 8.4097886z"
          />
          <path
            fill="#393939"
            transform="matrix(-1 0 0 1 12.245 0)"
            d="M5.72971809 9.19527339L5.22139558 10.2580848 7.0235254 9.79601072z"
          />
          <path
            fill="#E88F35"
            d="M0.97031441 0.0000410725908L7.25452135 4.75936261 6.19179213 2.17174799z"
          />
          <path
            d="M.97 0L.14 2.541.6 5.314l-.324.185.462.416-.37.323.509.462-.324.277.74.924L4.759 6.84c1.695-1.356 2.526-2.049 2.496-2.08C7.224 4.73 5.129 3.142.97 0z"
            fill="#8E5A30"
          />
        </g>
      </g>
    </svg>
  )
}

export default MetamaskLogo
