import styled from 'styled-components'

export const Header = styled.div`
  height: 298px;
  position: relative;
`

export const CloseIcon = styled.div`
  top: 8px;
  left: 0;
  position: absolute;

  cursor: pointer;
  width: 32px;
  height: 32px;
  border-radius: 4px;

  background-color: ${(p) => p.theme.colors.grey.grey3};

  > svg {
    margin: auto;
    display: block;
    height: 100%;
  }
`

export const LinkWallet = styled.div`
  width: 592px;
  padding: 0 8px 4px;
  border-radius: 12px;
  box-shadow: 0 32px 64px 0 rgba(0, 0, 0, 0.1);
  background-color: #f2f3f4;
`

export const WalletListItem = styled.div`
  width: 576px;
  height: 88px;
  padding: 30px 32px 30px 44px;
  margin-bottom: 4px;
  border-radius: 8px;
  background-color: #fff;

  justify-content: space-between;

  display: flex;
  align-items: center;
`
