import * as React from 'react'

function NamiLogo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle fill="white" cx="12" cy="12" r="12" />
      <g fill="#349EA3" transform="translate(5.000000, 5.000000)">
        <path d="M2.2172048,1.56473652 L1.86413838,1.20230042 C1.83841204,1.53457834 1.58743882,1.80548127 1.2580907,1.85647416 L1.61967501,2.1933566 L14.5898619,14.2849234 L2.2172048,1.56473652 Z M3.07325246,5.06899531 L3.07325246,12.2874788 C3.32898546,12.0184582 3.74828308,11.9904927 4.0374773,12.2231688 L4.0374773,7.38032401 L11.8522129,15 L13.1988909,15 L3.07325246,5.06899531 Z M12.1767444,3.4016189 C11.9906458,3.4020033 11.8116754,3.33007919 11.6775962,3.20102265 L11.6775962,8.08645685 L4.00553522,0.467206755 L2.5195898,0.467206755 L12.6426728,10.3764908 L12.6426728,3.22870579 C12.5131099,3.34032812 12.3477593,3.40169226 12.1767444,3.4016189 Z" />
        <path d="M1.14863585,3.21028335e-07 C0.514898322,-0.000468572762 0.000706239131,0.512771846 7.23782058e-07,1.14650916 C-0.00070334102,1.78024647 0.512345905,2.2946293 1.14608291,2.29557096 C1.77981992,2.29651003 2.29439342,1.78365203 2.29556936,1.14991541 L2.29556936,1.14991541 C2.29556936,0.515997457 1.78255168,0.00164413349 1.14863585,3.21028335e-07 Z M1.2580907,1.85434469 C1.22189843,1.86015858 1.18529183,1.86300735 1.14863585,1.86286782 C0.857997792,1.86320708 0.595778258,1.68841881 0.484277315,1.4200196 C0.372776372,1.15162038 0.43395835,0.842481592 0.639287771,0.6367866 C0.844617192,0.431091607 1.15364666,0.36935982 1.42224379,0.48038315 C1.69084093,0.59140648 1.86609536,0.85331468 1.86626785,1.14395289 L1.86626785,1.20017095 C1.84033453,1.53312603 1.58827548,1.80424837 1.2580907,1.85434469 L1.2580907,1.85434469 Z" />
        <path d="M12.1767444,1.53747867 C11.5552478,1.55441467 11.0602239,2.06311139 11.0602239,2.68483869 C11.0602239,3.30656599 11.5552478,3.8152627 12.1767444,3.83220389 C12.3373521,3.83271321 12.4962133,3.79887898 12.6426728,3.73296471 C13.1382853,3.51329359 13.4124095,2.97661458 13.2998679,2.44631137 C13.1873264,1.91600816 12.7188577,1.53692306 12.1767444,1.53747867 Z M12.6426728,3.22998347 C12.3863003,3.44883556 12.012165,3.45976062 11.7434589,3.25624127 C11.4747528,3.05272191 11.3839083,2.68961881 11.5251191,2.38354265 C11.6663299,2.07746649 12.0015312,1.91092315 12.3307572,1.98326477 C12.6599833,2.05560638 12.8944879,2.34733255 12.8943764,2.68441279 C12.893991,2.89389642 12.8020372,3.09274058 12.6426728,3.22870579 L12.6426728,3.22998347 Z" />
        <path d="M4.03918087,11.7274277 C3.57196388,11.5291006 3.02997817,11.6605553 2.70553769,12.0508933 C2.3810972,12.4412313 2.35095048,12.9981156 2.63135681,13.4211967 C2.91176314,13.8442779 3.43639056,14.0334733 3.92228714,13.8867427 C4.40818372,13.740012 4.74040741,13.2920653 4.73977799,12.7844975 C4.73984257,12.3235063 4.46409801,11.9072058 4.03960677,11.7274277 L4.03918087,11.7274277 Z M3.58986232,13.5021297 C3.19358064,13.5018944 2.87249951,13.1804955 2.87265622,12.7842138 C2.87281304,12.3879321 3.19414835,12.0667873 3.5904301,12.0668657 C3.98671184,12.066944 4.30792021,12.3882158 4.30792021,12.7844975 C4.30792021,12.9748992 4.23225435,13.1574963 4.09758011,13.2920906 C3.96290588,13.426685 3.78026394,13.5022425 3.58986232,13.5021297 Z" />
      </g>
    </svg>
  )
}

export default NamiLogo
