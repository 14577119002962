import React from 'react'

export const LinkWalletIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || 92}
    height={props.height || 92}
    viewBox="0 0 92 92"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g fillRule="nonzero" fill="none">
      <path
        d="M81.139 26.833h-22.55c.123 1.06.186 2.127.189 3.195a28.728 28.728 0 0 1-51.111 18.03v23.498c0 7.057 5.72 12.777 12.777 12.777H81.14a3.194 3.194 0 0 0 3.194-3.194V30.028a3.194 3.194 0 0 0-3.194-3.195zm-15.972 35.14a6.389 6.389 0 1 1 0-12.779 6.389 6.389 0 0 1 0 12.778z"
        fill="#000"
      />
      <g transform="translate(7 7)">
        <circle fill="#FD003D" cx="23" cy="23" r="23" />
        <circle fill="#FD003D" cx="23" cy="23" r="20" />
        <g fill="#FFF">
          <path d="M31.25 28.5h-6.417v-1.833h6.417c.506 0 .917-.41.917-.917v-5.5a.917.917 0 0 0-.917-.917h-6.417V17.5h6.417A2.75 2.75 0 0 1 34 20.25v5.5a2.75 2.75 0 0 1-2.75 2.75zM21.167 28.5H14.75A2.75 2.75 0 0 1 12 25.75v-5.5a2.75 2.75 0 0 1 2.75-2.75h6.417v1.833H14.75a.917.917 0 0 0-.917.917v5.5c0 .506.41.917.917.917h6.417V28.5z" />
          <path d="M26.667 23.917h-7.334a.917.917 0 0 1 0-1.834h7.334a.917.917 0 0 1 0 1.834z" />
        </g>
      </g>
    </g>
  </svg>
)
