import Image from '../assets/images/eternl.png'
import * as React from 'react'

// This is a placeholder, just embedded Eternl image
export const EternlLogo: React.VFC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <circle cx="12" cy="12" r="12" fill="#fff" />
      <image xlinkHref={Image.src} x="4" y="4" width="16" height="16" />
    </svg>
  )
}

export default EternlLogo
