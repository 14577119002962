import { CryptoUserError } from 'crypto/interface'
import { useCallback } from 'react'
import { Button, ButtonVariant } from 'ui/Common/components/Button'
import {
  NotificationType,
  useNotifications,
} from 'ui/Common/components/Notifications'
import { AddNotificationType } from 'ui/Common/components/Notifications/interface'

/**
 * Hook that wraps multiple steps of reporting crypto user errors.
 *
 * This is targeted specifically crypto errors.
 * For other types of errors another helper should be created.
 */
export function useReportCryptoUserError() {
  const { addNotification, removeNotification } = useNotifications()

  const reportCryptoUserError = useCallback(
    (message: string, error: unknown, actionCallback?: () => void) => {
      let newNotificationId: string | undefined = undefined
      const notification = cryptoErrorToNotification(error)
      const isInfo = notification?.type === NotificationType.Info

      function handleButtonClick() {
        actionCallback?.()

        if (newNotificationId) {
          removeNotification(newNotificationId)
        }
      }

      // action - only on "INSUFFICIENT_COLLATERAL" error
      const actions =
        error instanceof CryptoUserError &&
        error.code === 'INSUFFICIENT_COLLATERAL'
          ? [
              <Button
                key="learn-more"
                variant={ButtonVariant.SecondarySmall}
                onClick={handleButtonClick}
              >
                LEARN MORE
              </Button>,
            ]
          : undefined

      // create error notification
      newNotificationId = addNotification({
        title: isInfo ? undefined : message,
        content: notification?.content,
        type: notification?.type || NotificationType.Error,
        // if info, don't persist notification
        persist: !isInfo,
        actions,
      })

      // log error
      console.error(error)
    },
    [addNotification, removeNotification]
  )

  return { reportCryptoUserError }
}

/** Crypto error notifications */
const CRYPTO_ERROR_NOTIFICATIONS: Record<string, AddNotificationType> = {
  // --- Crypto user notifications
  //
  CANNOT_COVER_FEE: {
    content: 'Not enough ADA on wallet for fee',
    type: NotificationType.Error,
  },
  NOT_ENOUGH_MONEY: {
    content: 'Not enough funds on wallet',
    type: NotificationType.Error,
  },
  INSUFFICIENT_COLLATERAL: {
    content: 'Please add collateral to your wallet',
    type: NotificationType.Error,
  },
  //
  INSUFFICIENT_TRANSACTION_INPUT: {
    content: 'Not enough ada for transaction fee',
    type: NotificationType.Error,
  },
  INSUFFICIENT_MATIC_FUND: {
    content: 'Not enough MATIC for transaction fee',
    type: NotificationType.Error,
  },
  INSUFFICIENT_ADA_FUND: {
    content: 'Not enough ADA for transaction fee',
    type: NotificationType.Error,
  },
  //
  CANNOT_BALANCE_BY_AKAMON_API: {
    content: 'Cannot balance transaction due to insufficient funds',
    type: NotificationType.Error,
  },
  //
  NAMI_NOT_LINKED: {
    content: 'Nami is not linked',
    type: NotificationType.Error,
  },
  NAMI_TRANSACTION_CANCELED: {
    content: 'Transaction canceled',
    type: NotificationType.Info,
  },
  NAMI_UNEXPECTED_ERROR: {
    content: 'Unexpected Nami error',
    type: NotificationType.Error,
  },
  ETERNL_NOT_LINKED: {
    content: 'Eternl is not linked',
    type: NotificationType.Error,
  },
  ETERNL_TRANSACTION_CANCELED: {
    content: 'Transaction canceled',
    type: NotificationType.Info,
  },
  ETERNL_UNEXPECTED_ERROR: {
    content: 'Unexpected Eternl error',
    type: NotificationType.Error,
  },
  METAMASK_TRANSACTION_CANCELED: {
    content: 'Transaction canceled',
    type: NotificationType.Info,
  },
  NETWORK_SWITCH_REJECTED: {
    content: 'Network switch rejected',
    type: NotificationType.Info,
  },
}

/**
 * Map crypto error object to notification interface.
 *
 * If code or label for that code is not found it fallbacks to technical message.
 */
export function cryptoErrorToNotification(
  error: unknown
): AddNotificationType | undefined {
  const code = error instanceof CryptoUserError && error.code
  if (code) {
    return CRYPTO_ERROR_NOTIFICATIONS[code]
  }
}
