import styled, { keyframes } from 'styled-components'

const _backdrop = keyframes`
  from {
    backdrop-filter: blur(0);
    background-color: rgba(0, 0, 0, 0);
  } to {
    backdrop-filter: blur(4px);
    background-color: rgba(0, 0, 0, 0.2);
  }
`

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  height: 100%;
  width: 100%;

  animation: ${_backdrop} 0.1s;
  animation-fill-mode: forwards;
`

const _close = keyframes`
  from {
    transform: scale(0);
  } to {
    transform: scale(1);
  }
`

export const CloseButton = styled.button`
  position: absolute;
  z-index: 20;
  top: ${(p) => p.theme.spacing.xxs}px;
  left: ${(p) => p.theme.spacing.xxs}px;
  outline: none;
  border: none;
  cursor: pointer;
  width: ${(p) => p.theme.spacing.m}px;
  height: ${(p) => p.theme.spacing.m}px;
  border-radius: ${(p) => p.theme.container.borderRadius.xss}px;
  line-height: ${(p) => p.theme.spacing.m}px;

  transform: scale(0);

  animation: ${_close} 0.1s ease-in;
  animation-fill-mode: forwards;
  animation-delay: 0.6s;

  background-color: ${(p) => p.theme.colors.grey.grey3};

  :hover {
    background-color: ${(p) => p.theme.colors.grey.grey4};
  }
`

export const Content = styled.div`
  height: 100%;
  width: 100%;
`

const _wrapper = keyframes`
  from {
    top: 50px;
    opacity:0;
  } to {
    top: 0;
    opacity: 1;
  }
`

export const Popup = styled.div`
  & .ma-popup__wrapper--center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  & .ma-popup__wrapper--bottomLeft {
    top: auto;
    bottom: ${(p) => p.theme.spacing.mm}px;
    left: ${(p) => p.theme.spacing.m}px;
  }

  & .ma-popup__wrapper--bottomRight {
    top: auto;
    bottom: ${(p) => p.theme.spacing.mm}px;
    right: ${(p) => p.theme.spacing.m}px;
  }

  & .ma-popup__wrapper--topLeft {
    top: ${(p) => p.theme.spacing.mm}px;
    bottom: auto;
    left: ${(p) => p.theme.spacing.m}px;
  }

  & .ma-popup__wrapper--topRight {
    top: ${(p) => p.theme.spacing.mm}px;
    bottom: auto;
    right: ${(p) => p.theme.spacing.m}px;
  }
`

export const Wrapper = styled.div`
  z-index: 101;
  position: absolute;

  > ${Content} {
    opacity: 0;

    animation: ${_wrapper} 0.3s ease-in;
    animation-delay: 0.1s;
    animation-fill-mode: forwards;
  }
`
