import * as S from './styles'
import { ReactNode, useEffect } from 'react'
import { createPortal } from 'react-dom'
import { CloseIcon } from 'ui/Icons/components/CloseIcon'

export type PositionVariant =
  | 'center'
  | 'bottomLeft'
  | 'bottomRight'
  | 'topLeft'
  | 'topRight'

interface PopupInterface {
  children: ReactNode
  position?: PositionVariant
  onClose?: () => void
}

export function Popup({
  onClose,
  children,
  position = 'center',
}: PopupInterface) {
  useEffect(() => {
    // prevent scrolling of a page content
    document.body.style.overflow = 'hidden'

    return () => {
      document.body.style.overflow = 'initial'
    }
  }, [])

  // render popup outside of current container to avoid possible overflows and relative parents
  return createPortal(
    <S.Popup>
      <S.Backdrop onClick={onClose} />

      <S.Wrapper className={`ma-popup__wrapper--${position}`}>
        <S.CloseButton onClick={onClose}>
          <CloseIcon width={12} height={12} fill="#4D5461" />
        </S.CloseButton>

        <S.Content>{children}</S.Content>
      </S.Wrapper>
    </S.Popup>,
    document.body
  )
}
