import * as S from './styles'
import { push } from '@socialgouv/matomo-next'
import { useReportCryptoUserError } from 'app/containers/utils/errorHelpers'
import { LinkResult, LinkWalletStatus } from 'crypto/interface'
import React, { ReactNode, useCallback } from 'react'
import Box from 'ui/Common/components/Box'
import { Button, ButtonVariant } from 'ui/Common/components/Button'
import {
  NotificationType,
  useNotifications,
} from 'ui/Common/components/Notifications'
import Text from 'ui/Common/components/Text'
import WalletLogo from 'ui/Common/components/WalletLogo'
import { isAkamonEnabled, isEternlEnabled } from 'ui/Common/config'
import { LinkWalletIcon } from 'ui/Icons/components/LinkWalletIcon'

function Header() {
  return (
    <S.Header>
      {/* <S.CloseIcon>
        <CloseIcon width={12} height={12} fill="#4D5461" />
      </S.CloseIcon> */}
      <Box
        d="flex"
        style={{
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        <LinkWalletIcon />
        <Text m="24px 0 4px 0" color="grey.grey10" variant="heading.6">
          LINK WALLET
        </Text>
      </Box>
    </S.Header>
  )
}

type WalletListItemProps = {
  name: string
  link: () => Promise<LinkResult>
  status: LinkWalletStatus
  icon: ReactNode
  onSuccess: () => void
  comingSoon?: boolean
}

function WalletListItem({
  name,
  link,
  status,
  icon,
  onSuccess,
  comingSoon,
}: WalletListItemProps) {
  const { addNotification } = useNotifications()

  return (
    <S.WalletListItem>
      <Box d={'flex'} style={{ alignItems: 'center' }}>
        {icon}
        <Text
          style={{ textTransform: 'uppercase', marginLeft: '28px' }}
          color="grey.grey10"
          variant="heading.8"
        >
          {name}
        </Text>
      </Box>
      <Button
        variant={ButtonVariant.Secondary}
        onClick={async () => {
          if (comingSoon) return
          const result = await link()
          if (result === 'ok') {
            addNotification({
              type: NotificationType.Success,
              content: `Successfully connected ${name}!`,
            })
            push(['trackEvent', 'Wallet', 'LinkWallet', name])

            onSuccess()

            return
          }
          addNotification({
            type: NotificationType.Error,
            content: `Failed to connect ${name}!`,
          })
        }}
        disabled={comingSoon || status !== 'unlinked'}
        // Style override for a disabled state of the secondary button variant
        style={
          comingSoon
            ? {
                color: '#e5e6eb',
                borderColor: '#e5e6eb',
                backgroundColor: '#fff',
              }
            : {}
        }
      >
        {comingSoon
          ? 'Coming soon'
          : status === 'linked'
          ? 'CONNECTED'
          : status === 'linking'
          ? 'LINKING'
          : status === 'unavailable'
          ? 'UNAVAILABLE'
          : 'CONNECT'}
      </Button>
    </S.WalletListItem>
  )
}

function LinkWallet({
  linkMetamask,
  linkNami,
  linkEternl,
  metamaskStatus,
  namiStatus,
  eternlStatus,
  onSuccess,
}: {
  linkMetamask: () => Promise<LinkResult>
  linkNami: () => Promise<LinkResult>
  linkEternl: () => Promise<LinkResult>
  metamaskStatus: LinkWalletStatus
  namiStatus: LinkWalletStatus
  eternlStatus: LinkWalletStatus
  onSuccess: () => void
}) {
  const { reportCryptoUserError } = useReportCryptoUserError()

  const linkMetamaskInSwitch = useCallback(async () => {
    return await linkMetamask().catch((error) => {
      reportCryptoUserError('Chain switch failed', error)
      if (error.code === 'NETWORK_SWITCH_REJECTED') return 'ok'
      else return 'unknown'
    })
  }, [linkMetamask, reportCryptoUserError])

  return (
    <S.LinkWallet>
      <Header />
      <WalletListItem
        name="Metamask wallet"
        link={linkMetamaskInSwitch}
        status={metamaskStatus}
        icon={<WalletLogo walletId="metamask" width={24} height={24} />}
        onSuccess={onSuccess}
        comingSoon={!isAkamonEnabled}
      />
      <WalletListItem
        name="Nami wallet"
        link={linkNami}
        status={namiStatus}
        icon={<WalletLogo walletId="nami" width={24} height={24} />}
        onSuccess={onSuccess}
      />
      {isEternlEnabled && (
        <WalletListItem
          name="Eternl wallet"
          link={linkEternl}
          status={eternlStatus}
          icon={<WalletLogo walletId="eternl" width={24} height={24} />}
          onSuccess={onSuccess}
        />
      )}
    </S.LinkWallet>
  )
}

export default LinkWallet
